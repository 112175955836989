<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content" when="false">
    <ion-menu contentId="main-content" [swipeGesture]="false">
      <ion-content>
        <ion-list lines="none">
          <ion-list-header>
            Conference
          </ion-list-header>
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>

          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            Account
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/account" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label>
                Account
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                Logout
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>

        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-list-header>
            Account
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label>
                Login
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/signup" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person-add"></ion-icon>
              <ion-label>
                Signup
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item>
            <ion-icon slot="start" name="moon-outline"></ion-icon>
            <ion-label>
              Dark Mode
            </ion-label>
            <ion-toggle [(ngModel)]="dark"></ion-toggle>
          </ion-item>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            Tutorial
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="openTutorial()" detail="false">
              <ion-icon slot="start" name="hammer"></ion-icon>
              <ion-label>Show Tutorial</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
