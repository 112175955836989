// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyA_R8lqEz65ljQAGn6GRWa47Q696eXYtkY",
    authDomain: "oneliner-dd6fd.firebaseapp.com",
    projectId: "oneliner-dd6fd",
    storageBucket: "oneliner-dd6fd.appspot.com",
    messagingSenderId: "279342091434",
    appId: "1:279342091434:web:54b097929252aff35194d3",
    measurementId: "G-LYR4C04BNJ"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
